import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  // <Svg viewBox="0 0 24 24" {...props}>
  //   <path d="M21.9 9.78661L20.85 5.41661C20.63 4.51661 19.85 3.89661 18.94 3.89661H16.73H14.72H13H11H9.27999H7.25999H5.04999C4.14999 3.89661 3.35999 4.52661 3.14999 5.41661L2.09999 9.78661C1.85999 10.8066 2.07999 11.8466 2.71999 12.6666C2.79999 12.7766 2.90999 12.8566 2.99999 12.9566V19.8966C2.99999 20.9966 3.89999 21.8966 4.99999 21.8966H19C20.1 21.8966 21 20.9966 21 19.8966V12.9566C21.09 12.8666 21.2 12.7766 21.28 12.6766C21.92 11.8566 22.15 10.8066 21.9 9.78661ZM7.01999 5.89661L6.43999 10.7566C6.35999 11.4066 5.83999 11.8966 5.22999 11.8966C4.73999 11.8966 4.42999 11.6066 4.29999 11.4266C4.03999 11.0966 3.94999 10.6666 4.04999 10.2566L5.04999 5.89661H7.01999ZM18.91 5.88661L19.96 10.2566C20.06 10.6766 19.97 11.0966 19.71 11.4266C19.57 11.6066 19.27 11.8966 18.77 11.8966C18.16 11.8966 17.63 11.4066 17.56 10.7566L16.98 5.89661L18.91 5.88661ZM15.51 10.4166C15.56 10.8066 15.44 11.1966 15.18 11.4866C14.95 11.7466 14.63 11.8966 14.22 11.8966C13.55 11.8966 13 11.3066 13 10.5866V5.89661H14.96L15.51 10.4166ZM11 10.5866C11 11.3066 10.45 11.8966 9.70999 11.8966C9.36999 11.8966 9.05999 11.7466 8.81999 11.4866C8.56999 11.1966 8.44999 10.8066 8.48999 10.4166L9.03999 5.89661H11V10.5866ZM18 19.8966H5.99999C5.44999 19.8966 4.99999 19.4466 4.99999 18.8966V13.8666C5.07999 13.8766 5.14999 13.8966 5.22999 13.8966C6.09999 13.8966 6.88999 13.5366 7.46999 12.9466C8.06999 13.5466 8.86999 13.8966 9.77999 13.8966C10.65 13.8966 11.43 13.5366 12.01 12.9666C12.6 13.5366 13.4 13.8966 14.3 13.8966C15.14 13.8966 15.94 13.5466 16.54 12.9466C17.12 13.5366 17.91 13.8966 18.78 13.8966C18.86 13.8966 18.93 13.8766 19.01 13.8666V18.8966C19 19.4466 18.55 19.8966 18 19.8966Z" />
  // </Svg>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M3.83226 10.5498C2.12099 12.261 -1.62243 18.6783 0.784052 21.1383C1.31882 21.6731 2.06751 21.9405 3.0301 21.9405C3.99269 21.9405 5.16919 21.6731 6.50612 21.1383C8.37783 20.3896 10.41 19.1596 11.3725 18.1435C13.4582 16.0579 13.4582 12.6889 11.3725 10.6033C9.28693 8.46417 5.91787 8.46415 3.83226 10.5498ZM9.87519 16.5927C9.17999 17.2879 7.52219 18.3574 5.70396 19.1061C3.67183 19.9083 2.60228 19.8548 2.33489 19.5874C1.58621 18.8388 3.29748 14.0793 5.3831 12.0472C6.02482 11.4054 6.82698 11.0845 7.62914 11.0845C8.21739 11.0845 8.80564 11.245 9.34042 11.5658C8.85912 11.6193 8.21739 11.9402 7.62914 12.475C6.7735 13.3306 6.45264 14.3467 6.88046 14.721C7.30828 15.1488 8.32434 14.7745 9.1265 13.9723C9.71475 13.3841 10.0356 12.6889 10.0356 12.2076C11.1052 13.5445 11.0517 15.4162 9.87519 16.5927Z" fill="#794BCC"/>
  <path d="M19.4895 2.27132L19.4991 2.28087L19.5089 2.29016C20.3785 3.11399 21.5415 4.99866 22.2629 6.80205C23.3037 9.40425 23.1023 10.8507 22.5196 11.5169C22.1825 11.8442 21.7316 12.0084 20.9988 12.0084C19.7696 12.0084 18.1775 11.4551 16.6637 10.6754C15.1614 9.9017 13.9075 8.995 13.3634 8.45088C12.5418 7.62924 12.1055 6.5497 12.1055 5.36112C12.1055 4.51282 12.3277 3.72005 12.7537 3.03695L12.8326 3.05951L12.7447 3.21766C12.405 3.82916 12.1911 4.5296 12.1911 5.30762C12.1911 6.45511 12.6903 7.50221 13.4419 8.25607C14.5987 9.44115 16.4419 10.4532 18.0442 11.0732C18.8554 11.3871 19.658 11.6215 20.337 11.7236C20.6738 11.7743 21.0195 11.7986 21.3389 11.7663C21.6192 11.738 22.0345 11.6514 22.3651 11.3437C22.6842 11.0616 22.7878 10.676 22.8284 10.445C22.8789 10.1583 22.8786 9.83631 22.8419 9.49806C22.7682 8.81838 22.5334 7.94645 22.0805 6.89741C21.3073 5.02229 20.1863 3.24941 19.368 2.42316C18.57 1.56272 17.4427 1.15248 16.3997 1.15248C15.6217 1.15248 14.9213 1.36641 14.3098 1.70613L13.9392 1.91201L13.898 1.81588C15.5924 0.592992 17.963 0.744803 19.4895 2.27132ZM15.1282 4.11924C14.9533 3.9444 14.8191 3.78176 14.7178 3.63225C14.8463 3.70885 15.0107 3.83541 15.2113 4.03606C15.3704 4.19514 15.4997 4.35719 15.5993 4.50892C15.4506 4.40994 15.2899 4.28093 15.1282 4.11924Z" fill="black" stroke="#794BCC" stroke-width="2"/>
  </svg>
);

export default Icon;
