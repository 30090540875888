import { useMemo } from "react";
import { useRouter } from "next/router";
import {
  Menu as UikitMenu,
  NextLinkFromReactRouter,
  footerLinks,
} from "@pancakeswap/uikit";
import { useTranslation, languageList } from "@pancakeswap/localization";
import PhishingWarningBanner from "components/PhishingWarningBanner";
import { NetworkSwitcher } from "components/NetworkSwitcher";
import useTheme from "hooks/useTheme";
import { useCakeBusdPrice } from "hooks/useBUSDPrice";
import { usePhishingBannerManager } from "state/user/hooks";
import UserMenu from "./UserMenu";
import { useMenuItems } from "./hooks/useMenuItems";
import GlobalSettings from "./GlobalSettings";
import { getActiveMenuItem, getActiveSubMenuItem } from "./utils";
import { SettingsMode } from "./GlobalSettings/types";
import { TropicalSwap } from "./TropicalToken/TropicalSwap";
import { useActiveChainId } from "hooks/useActiveChainId";

const Menu = (props) => {
  const { isDark, setTheme } = useTheme();
  const cakePriceUsd = useCakeBusdPrice({ forceMainnet: true });
  const { currentLanguage, setLanguage, t } = useTranslation();
  const { pathname } = useRouter();
  const [showPhishingWarningBanner] = usePhishingBannerManager();
  const { chainId } = useActiveChainId();

  const menuItems = useMenuItems();

  const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname });
  const activeSubMenuItem = getActiveSubMenuItem({
    menuItem: activeMenuItem,
    pathname,
  });

  const toggleTheme = useMemo(() => {
    return () => setTheme(isDark ? "light" : "dark");
  }, [setTheme, isDark]);

  const getFooterLinks = useMemo(() => {
    return footerLinks(t);
  }, [t]);

  return (
    <>
      <UikitMenu
        linkComponent={(linkProps) => {
          return (
            <NextLinkFromReactRouter
              to={linkProps.href}
              {...linkProps}
              prefetch={false}
            />
          );
        }}
        rightSide={
          <>
            {/* <GlobalSettings mode={SettingsMode.GLOBAL} /> */}
            <NetworkSwitcher />
            <TropicalSwap />
            <UserMenu />
          </>
        }
        banner={
          showPhishingWarningBanner &&
          typeof window !== "undefined" && <PhishingWarningBanner />
        }
        isDark={isDark}
        toggleTheme={toggleTheme}
        currentLang={currentLanguage.code}
        langs={languageList}
        setLang={setLanguage}
        cakePriceUsd={cakePriceUsd}
        links={menuItems}
        subLinks={
          activeMenuItem?.hideSubNav || activeSubMenuItem?.hideSubNav
            ? []
            : activeMenuItem?.items
        }
        footerLinks={getFooterLinks}
        activeItem={activeMenuItem?.href}
        activeSubItem={activeSubMenuItem?.href}
        buyCakeLabel={t("Buy Papple")}
        chainId={chainId}
        /* buyCakeLink="https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82&chainId=56" */
        // buyCakeLink="/swap?outputCurrency=0xfcf5c02ca529081d65e40c3f770a2123c8300aa4&chainId=5001" */
        buyCakeLink="/swap?outputCurrency=0x2b19015bd5B9270097d1cEc431c800d11e9f4841&chainId=5000"
        {...props}
      />
    </>
  );
};

export default Menu;
