import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  EarnIcon,
  SeedIcon,
  SeedFillIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
  LaunchpadIcon,
  LaunchpadIconFill,
  MoreIconFooter,
} from "@pancakeswap/uikit";
import { ContextApi } from "@pancakeswap/localization";
import { nftsBaseUrl } from "views/Nft/market/constants";
import { getPerpetualUrl } from "utils/getPerpetualUrl";
import { SUPPORT_ONLY_BSC, SUPPORT_ONLY_MANTLE } from "config/constants/supportChains";

export type ConfigMenuDropDownItemsType = DropdownMenuItems & {
  hideSubNav?: boolean;
};
export type ConfigMenuItemsType = Omit<MenuItemsType, "items"> & {
  hideSubNav?: boolean;
  image?: string;
} & {
  items?: ConfigMenuDropDownItemsType[];
};

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item;
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item;
  }
  return {
    ...item,
    disabled: true,
  };
};

const config: (
  t: ContextApi["t"],
  isDark: boolean,
  languageCode?: string,
  chainId?: number
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t("Trade"),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: "/swap",
      /*showItemsOnMobile: false,*/
      items: [
        {
          label: t("Swap"),
          href: "/swap",
        },
        // {
        //   label: t('Limit'),
        //   href: '/limit-orders',
        //   supportChainIds: SUPPORT_ONLY_BSC,
        //   image: '/images/decorations/3d-coin.png',
        // },
        {
          label: t("Liquidity"),
          href: "/liquidity",
        },
        // {
        //   label: t('Perpetual'),
        //   href: getPerpetualUrl({
        //     chainId,
        //     languageCode,
        //     isDark,
        //   }),
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Bridge'),
        //   href: 'https://bridge.pancakeswap.finance/',
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t("Earn"),
      href: "/farms",
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      /*  image: "/images/decorations/pe2.png", */
      items: [
        {
          label: t("Farms"),
          href: "/farms",
        },
        {
          label: t("Pools"),
          href: "/pools",
          // supportChainIds: SUPPORT_ONLY_BSC,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t("SEEDS"),
      href: "/seeds-dashboard",
      icon: SeedIcon,
      fillIcon: SeedFillIcon,
      supportChainIds: SUPPORT_ONLY_MANTLE,
      image: "/images/decorations/pe2.png",
      items: [
        {
          label: t("SEEDS Dashboard"),
          href: "/seeds-dashboard",
          supportChainIds: SUPPORT_ONLY_MANTLE,
        },
        {
          label: t("Dividends"),
          href: "/seeds-dividends",
          supportChainIds: SUPPORT_ONLY_MANTLE,
          // supportChainIds: SUPPORT_ONLY_BSC,
        },
        // {
        //   label: t("Launchpad"),
        //   href: "/seeds-dashboard",
        // },
        // {
        //   label: t("Yield Booster"),
        //   href: "/seeds-dashboard",
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t("Launchpad"),
        //  href: "/launchpad/upcoming",
      href: "/launchpad",
      icon: LaunchpadIcon,
      fillIcon: LaunchpadIconFill,
      /*       image: "/images/decorations/pe2.png", */
      showItemsOnMobile: false,
      // supportChainIds: SUPPORT_ONLY_MANTLE,
      items: [],
    },
    // {
    //   label: t('Win'),
    //   href: '/prediction',
    //   icon: TrophyIcon,
    //   fillIcon: TrophyFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   items: [
    //     {
    //       label: t('Trading Competition'),
    //       href: '/competition',
    //       image: '/images/decorations/tc.png',
    //       hideSubNav: true,
    //     },
    //     {
    //       label: t('Prediction (BETA)'),
    //       href: '/prediction',
    //       image: '/images/decorations/prediction.png',
    //     },
    //     {
    //       label: t('Lottery'),
    //       href: '/lottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //     {
    //       label: t('Pottery (BETA)'),
    //       href: '/pottery',
    //       image: '/images/decorations/lottery.png',
    //     },
    //   ],
    // },
    // {
    //   label: t('NFT'),
    //   href: `${nftsBaseUrl}`,
    //   icon: NftIcon,
    //   fillIcon: NftFillIcon,
    //   supportChainIds: SUPPORT_ONLY_BSC,
    //   image: '/images/decorations/nft.png',
    //   items: [
    //     {
    //       label: t('Overview'),
    //       href: `${nftsBaseUrl}`,
    //     },
    //     {
    //       label: t('Collections'),
    //       href: `${nftsBaseUrl}/collections`,
    //     },
    //     {
    //       label: t('Activity'),
    //       href: `${nftsBaseUrl}/activity`,
    //     },
    //   ],
    // },
    {
      label: "",
      /*       href: "/info", */
      icon: MoreIcon,
      hideSubNav: true,
      items: [
        /*         {
          label: t("Dexscreener"),
          href: "https://docs.pancakeswap.finance",
          type: DropdownMenuItemType.EXTERNAL_LINK,
        }, */
        {
          label: t("Dashboard"),
          href: "/info",
        },
        {
          label: "SEEDS Drop",
          href: "/seedsdrop",
          supportChainIds: SUPPORT_ONLY_MANTLE,
        },
        {
          label: "Boarding Pass",
          href: "/boardingpass",
          supportChainIds: SUPPORT_ONLY_MANTLE,
        },
        {
          label: t("Docs"),
          href: "https://docs.tropicalswap.exchange/",
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t("Dexscreener"),
          href: "https://dexscreener.com/mantle/0xa6ebf2669a6174c5dc1be5299cd1c4461cc1c3c2",
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
        {
          label: t("GeckoTerminal"),
          href: "https://www.geckoterminal.com/mantle/tropicalswap/pools",
          type: DropdownMenuItemType.EXTERNAL_LINK,
        },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
  ].map((item) => addMenuItemSupported(item, chainId));

export default config;
