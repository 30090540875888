import { SerializedFarmConfig } from 'config/constants/types'
import BigNumber from 'bignumber.js'
import { getFullDecimalMultiplier } from '@pancakeswap/utils/getFullDecimalMultiplier'
import { BIG_ZERO, BIG_TWO } from '@pancakeswap/utils/bigNumber'
import { fetchPublicFarmsData } from './fetchPublicFarmData'
import { fetchMasterChefData } from './fetchMasterChefData'
import { FIXED_ZERO } from '@pancakeswap/farms/src/const'

const fetchFarms = async (farmsToFetch: SerializedFarmConfig[]) => {
  const farmResult = await fetchPublicFarmsData(farmsToFetch)
  const masterChefResult = await fetchMasterChefData(farmsToFetch)

  return farmsToFetch.map((farm, index) => {
    const [tokenBalanceLP, quoteTokenBalanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
      farmResult[index]

    const [info, totalAllocPoint] = masterChefResult[index]

    const lpTotalSupplyBN = new BigNumber(lpTotalSupply)

    // Ratio in % of LP tokens that are staked in the MC, vs the total number in circulation
    const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(lpTotalSupplyBN)

    // Raw amount of token in the LP, including those not staked
    const tokenAmountTotal = new BigNumber(tokenBalanceLP).div(getFullDecimalMultiplier(tokenDecimals))
    const quoteTokenAmountTotal = new BigNumber(quoteTokenBalanceLP).div(getFullDecimalMultiplier(quoteTokenDecimals))

    // Amount of quoteToken in the LP that are staked in the MC
    const quoteTokenAmountMc = quoteTokenAmountTotal.times(lpTokenRatio)

    // Total staked in LP, in quote token value
    const lpTotalInQuoteToken = quoteTokenAmountMc.times(BIG_TWO)

    const allocPoint = info ? new BigNumber(info.allocPoint?._hex) : BIG_ZERO
    const seedRewardShare = info ? new BigNumber(info.seedsRewardShare?._hex) : BIG_ZERO
    const poolWeight = totalAllocPoint ? allocPoint.div(new BigNumber(totalAllocPoint)) : BIG_ZERO

    return {
      ...farm,
      // token: farm.token,
      // quoteToken: farm.quoteToken,
      tokenAmountTotal: tokenAmountTotal.toString(),
      quoteTokenAmountTotal: quoteTokenAmountTotal.toString(),
      lpTotalSupply: lpTotalSupplyBN.toString(),
      lpTotalInQuoteToken: lpTotalInQuoteToken.toString(),
      tokenPriceVsQuote: 
        !quoteTokenAmountTotal.isZero() && !tokenAmountTotal.isZero() 
          ? quoteTokenAmountTotal.div(tokenAmountTotal).toString()
          : FIXED_ZERO.toString(),
      lpTokenStakedAmount: lpTokenBalanceMC.toString(),
      poolWeight: poolWeight.toString(),
      seedRewardShare: seedRewardShare.toString(),
      multiplier: !allocPoint.isZero() ? `${allocPoint.div(10).toString()}X`: '0X',
    }
    // return {
    //   ...farm,
    //   token: farm.token,
    //   quoteToken: farm.quoteToken,
    //   tokenAmountTotal: tokenAmountTotal.toJSON(),
    //   lpTotalSupply: lpTotalSupplyBN.toJSON(),
    //   lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    //   tokenPriceVsQuote: quoteTokenAmountTotal.div(tokenAmountTotal).toJSON(),
    //   poolWeight: poolWeight.toJSON(),
    //   multiplier: `${allocPoint.div(10).toString()}X`,
    // }
  })
}

export default fetchFarms
