import { Skeleton } from "@pancakeswap/uikit";
import { useCakeBusdPrice, usePappleUsdcPrice } from "hooks/useBUSDPrice";

export const TropicalSwap = () => {
  const showSkeleton = true;
  const PapplePriceUsd = usePappleUsdcPrice({ forceMainnet: true });
  return PapplePriceUsd ? (
    <div className="hidden justify-center items-center lg:flex">
      <img
        style={{ marginRight: "0.5rem" }}
        width={24}
        height={24}
        src="/images/tokens/tropilcalswap-token.png"
        alt="Pineapple"
      />
      <span className="mr-4" style={{ color: "white" }}>
      ${PapplePriceUsd? PapplePriceUsd.toFixed(3) : 0}
      </span>
    </div>
  ) : showSkeleton ? (
    <Skeleton width={80} height={24} marginRight={3} />
  ) : null;

};
