import "tailwindcss/base.css";
import "tailwindcss/components.css";
import "tailwindcss/utilities.css";

import { createGlobalStyle } from "styled-components";
import { PancakeTheme } from "@pancakeswap/uikit";

declare module "styled-components" {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Mitr';
    src: url('/fonts/Mitr-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Mitr';
    src: url('/fonts/Mitr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    img {
      height: auto;
      max-width: 100%;
    }
  }
`;

export default GlobalStyle;
