import { BigNumber } from "@ethersproject/bignumber";
import { Pool } from "@pancakeswap/uikit";
import { SerializedWrappedToken } from "@pancakeswap/token-lists";
import Trans from "components/Trans";
import { VaultKey } from "state/types";
import { bscTokens, mantleTestnetTokens, mantleTokens } from "@pancakeswap/tokens";
import { PoolCategory } from "./types";
import { ChainId, ERC20Token } from "@pancakeswap/sdk";

export const MAX_LOCK_DURATION = 31536000;
export const UNLOCK_FREE_DURATION = 604800;
export const ONE_WEEK_DEFAULT = 604800;
export const BOOST_WEIGHT = BigNumber.from("20000000000000");
export const DURATION_FACTOR = BigNumber.from("31536000");

export const vaultPoolConfig = {
  [VaultKey.CakeVaultV1]: {
    name: <Trans>Auto CAKE</Trans>,
    description: <Trans>Automatic restaking</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 380000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: "/images/tokens/autorenew.svg",
    },
  },
  [VaultKey.CakeVault]: {
    name: <Trans>Stake CAKE</Trans>,
    description: <Trans>Stake, Earn – And more!</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 600000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: "/images/tokens/autorenew.svg",
    },
  },
  [VaultKey.CakeFlexibleSideVault]: {
    name: <Trans>Flexible CAKE</Trans>,
    description: <Trans>Flexible staking on the side.</Trans>,
    autoCompoundFrequency: 5000,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: "/images/tokens/autorenew.svg",
    },
  },
  [VaultKey.IfoPool]: {
    name: "IFO CAKE",
    description: <Trans>Stake CAKE to participate in IFOs</Trans>,
    autoCompoundFrequency: 1,
    gasLimit: 500000,
    tokenImage: {
      primarySrc: `/images/tokens/${bscTokens.cake.address}.svg`,
      secondarySrc: `/images/tokens/ifo-pool-icon.svg`,
    },
  },
} as const;

export const livePools: Pool.SerializedPoolConfig<SerializedWrappedToken>[] = [
  /*  {
    sousId: 0,
    stakingToken: mantleTestnetTokens.mnt,
    earningToken: mantleTestnetTokens.usdc,
    contractAddress: {
      5001: '0x8c5cFfad6cddb96Ee33DA685D0d50a37e030E115',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '10',
    isFinished: false,
  },*/
  // {
  //   sousId: 0,
  //   stakingToken: bscTokens.cake,
  //   earningToken: bscTokens.cake,
  //   contractAddress: {
  //     97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
  //     56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: '10',
  //   isFinished: false,
  // },
  {
    sousId: 5,
    stakingToken: new ERC20Token(
      ChainId.MANTLE,
      "0xfc734d145e2941d70bc5e178a8f946e58fa96186",
      18,
      "SEEDS",
      "SEEDS",
      "https://tropilcalswap.netlify.app/images/symbol/PAPPLE.png"
    ),
    earningToken: new ERC20Token(
      ChainId.MANTLE,
      "0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9",
      6,
      "USDC",
      "USDC",
      "https://tropilcalswap.netlify.app/images/symbol/USDC.png"
    ),
    contractAddress: {
      5000: "0x661eb6Bc181F3ab5088294D7843b3210766676f2",
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: "0.000132",
    isFinished: false,
  },
  {
    sousId: 6,
    stakingToken: new ERC20Token(
      ChainId.MANTLE,
      "0xfc734d145e2941d70bc5e178a8f946e58fa96186",
      18,
      "SEEDS",
      "SEEDS",
      "https://tropilcalswap.netlify.app/images/symbol/PAPPLE.png"
    ),
    earningToken: mantleTokens.mnt,
    contractAddress: {
      5000: "0x5F1C487C2c0042732A3b402f15f7F88B50D38a3E",
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: "0.00008267195768",
    isFinished: false,
  },
  {
    sousId: 7,
    stakingToken: new ERC20Token(
      ChainId.MANTLE,
      "0xfc734d145e2941d70bc5e178a8f946e58fa96186",
      18,
      "SEEDS",
      "SEEDS",
      "https://tropilcalswap.netlify.app/images/symbol/PAPPLE.png"
    ),
    earningToken: mantleTokens.meth,
    contractAddress: {
      5000: "0xeC051914FfBFf9e75d21d4F0a0e6FcA98427FD8A",
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: "0.00000003466974005",
    isFinished: false,
  },
  /*{
    sousId: 323,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.sd,
    contractAddress: {
      56: '0xaEC63F134a7853C6DaC9BA428d7962cD7C6c5e30',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.01022',
    version: 3,
  },
  {
    sousId: 322,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.pstake,
    contractAddress: {
      56: '0x98AC153577d65f2eEF2256f3AeF8ba9D7E4B756B',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.1186',
    version: 3,
  },
  {
    sousId: 321,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.csix,
    contractAddress: {
      56: '0x8BD7b0d392D2dE8F682704A3186A48467FcDC7AC',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '8.68',
    version: 3,
  },
  {
    sousId: 320,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.axlusdc,
    contractAddress: {
      56: '0x08287F4942A7B68DDb87D20Becd4fdadF4aE206e',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '0.0135',
    version: 3,
  },
  {
    sousId: 306,
    stakingToken: bscTokens.cake,
    earningToken: bscTokens.squad,
    contractAddress: {
      56: '0x08C9d626a2F0CC1ed9BD07eBEdeF8929F45B83d3',
      97: '',
    },
    poolCategory: PoolCategory.CORE,
    tokenPerBlock: '2.459',
    version: 3,
  },*/
].map((p) => ({
  ...p,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}));

// known finished pools
const finishedPools = [
  // {
  //   sousId: 310,
  //   stakingToken: bscTokens.cake,
  //   earningToken: bscTokens.champ,
  //   contractAddress: {
  //     56: "0x3B48325b7CA831ca7D5b649B074fF697c66166c3",
  //     97: "",
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: "1.961",
  //   version: 3,
  // },
  // {
  //   sousId: 303,
  //   stakingToken: bscTokens.cake,
  //   earningToken: bscTokens.mgp,
  //   contractAddress: {
  //     56: "0x365F744c8b7608253697cA2Ed561537B65a3438B",
  //     97: "",
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: "6.944",
  //   version: 3,
  // },
  // {
  //   sousId: 309,
  //   stakingToken: bscTokens.cake,
  //   earningToken: bscTokens.arena,
  //   contractAddress: {
  //     56: "0xDe9FC6485b5f4A1905d8011fcd201EB78CF34073",
  //     97: "",
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   tokenPerBlock: "2.893",
  //   version: 3,
  // },
].map((p) => ({
  ...p,
  isFinished: true,
  stakingToken: p.stakingToken.serialize,
  earningToken: p.earningToken.serialize,
}));

export default [
  ...livePools,
  ...finishedPools,
] as Pool.SerializedPoolConfig<SerializedWrappedToken>[];
