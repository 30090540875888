import BigNumber from "bignumber.js";
import { ChainId } from "@pancakeswap/sdk";
import { BLOCKS_PER_YEAR } from "config";
import lpAprs56 from "config/constants/lpAprs/56.json";
import lpAprs1 from "config/constants/lpAprs/1.json";
import lpAprs5001 from "config/constants/lpAprs/5001.json";
import lpAprs5000 from "config/constants/lpAprs/5000.json";

const getLpApr = (chainId: number) => {
  switch (chainId) {
    case ChainId.BSC:
      return lpAprs56;
    case ChainId.ETHEREUM:
      return lpAprs1;
    case ChainId.MANTLE_TESTNET:
      return lpAprs5001;
    case ChainId.MANTLE:
      return lpAprs5000;
    default:
      return {};
  }
};

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerBlock Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
  stakingTokenPrice: number,
  rewardTokenPrice: number,
  totalStaked: number,
  tokenPerBlock: number
): number => {
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice)
    .times(tokenPerBlock)
    .times(BLOCKS_PER_YEAR);
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(
    totalStaked
  );
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);
  return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber();
};

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param cakePriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @param farmAddress Farm Address
 * @returns Farm Apr
 */
export const getFarmApr = (
  chainId: number,
  poolWeight: BigNumber,
  cakePriceUsd: BigNumber,
  poolLiquidityUsd: BigNumber,
  farmAddress: string,
  regularCakePerBlock: number
): { cakeRewardsApr: number; lpRewardsApr: number } => {
  // console.log('getFarmApr', chainId, " , ", poolWeight.toNumber(), " , ", cakePriceUsd, " , ", poolLiquidityUsd.toNumber(), " , ", farmAddress, " , ", regularCakePerBlock)
  const yearlyCakeRewardAllocation = poolWeight
    ? poolWeight.times(BLOCKS_PER_YEAR * regularCakePerBlock)
    : new BigNumber(NaN);
  const cakeRewardsApr = yearlyCakeRewardAllocation
    .times(cakePriceUsd)
    .div(poolLiquidityUsd)
    .times(100);
  // console.log('cakeRewardsApr', cakeRewardsApr)
  if (cakeRewardsApr.isNaN() || !cakeRewardsApr.isFinite()) {
    return { cakeRewardsApr: 0, lpRewardsApr: 0 };
  }
  
  let cakeRewardsAprAsNumber = null;
  if (!cakeRewardsApr.isNaN() && cakeRewardsApr.isFinite()) {
    cakeRewardsAprAsNumber = cakeRewardsApr.toNumber();
  }
  const lpRewardsApr =
    (getLpApr(chainId)[farmAddress?.toLowerCase()] ||
      getLpApr(chainId)[farmAddress]) ??
    0; // can get both checksummed or lowercase
  // console.log('lpRewardsApr', lpRewardsApr)
  return { cakeRewardsApr: cakeRewardsAprAsNumber, lpRewardsApr };
};

export default null;
