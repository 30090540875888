import { StaticJsonRpcProvider } from '@ethersproject/providers'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://bsc.nodereal.io'

export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)

export const MANTLE_NODE = 'https://rpc.mantle.xyz'

export const mantleProvider = new StaticJsonRpcProvider(MANTLE_NODE)

export default null
