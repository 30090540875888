import { useAccount } from "wagmi";
import BigNumber from "bignumber.js";
import { CAKE, PAPPLE } from "@pancakeswap/tokens";
import { FAST_INTERVAL } from "config/constants";
import { BigNumber as EthersBigNumber } from "@ethersproject/bignumber";
import { Zero } from "@ethersproject/constants";
import { ChainId } from "@pancakeswap/sdk";
import { useMemo } from "react";
import useSWR from "swr";
import { BIG_ZERO } from "@pancakeswap/utils/bigNumber";
import { bscRpcProvider } from "utils/providers";
import { useWeb3React } from "@pancakeswap/wagmi";
import { useTokenContract } from "./useContract";
import { useSWRContract } from "./useSWRContract";

const useTokenBalance = (tokenAddress: string, forceBSC?: boolean, accountOf?: string) => {
  const { address: account } = useAccount();
  const accountToUse = accountOf? accountOf : account;

  const contract = useTokenContract(tokenAddress, false);

  const key = useMemo(
    () =>
      account
        ? {
            contract: forceBSC ? contract.connect(bscRpcProvider) : contract,
            methodName: "balanceOf",
            params: [accountToUse],
          }
        : null,
    [account, contract, forceBSC]
  );

  const { data, status, ...rest } = useSWRContract(key as any, {
    refreshInterval: FAST_INTERVAL,
  });

  return {
    ...rest,
    fetchStatus: status,
    balance: data ? new BigNumber(data.toString()) : BIG_ZERO,
  };
};

export const useGetBnbBalance = () => {
  const { address: account } = useAccount();
  const { status, data, mutate } = useSWR([account, "bnbBalance"], async () => {
    return bscRpcProvider.getBalance(account);
  });

  return { balance: data || Zero, fetchStatus: status, refresh: mutate };
};

export const useGetCakeBalance = () => {
  const { chainId } = useWeb3React();
  const { balance, fetchStatus } = useTokenBalance(
    /*  CAKE[chainId]?.address || CAKE[ChainId.MANTLE_TESTNET]?.address */
    CAKE[chainId]?.address || CAKE[ChainId.MANTLE]?.address
  );

  // TODO: Remove ethers conversion once useTokenBalance is converted to ethers.BigNumber
  return { balance: EthersBigNumber.from(balance.toString()), fetchStatus };
};

export const useGetPappleBalance = () => {
  const { chainId } = useWeb3React();
  const { balance, fetchStatus } = useTokenBalance(
    PAPPLE[chainId]?.address
  );
  return { balance: EthersBigNumber.from(balance.toString()), fetchStatus };
}

export default useTokenBalance;
