import { DefaultSeoProps } from "next-seo";

export const SEO: DefaultSeoProps = {
  titleTemplate: "%s | TropicalSwap",
  defaultTitle: "TropicalSwap",
  description:
    "The most exotic AMM on Mantle Network! Trade, stake, and earn crypto in our ecosystem. Powered by $PAPPLE.",
  twitter: {
    cardType: "summary_large_image",
    handle: "@TropicalSwap",
    site: "@TropicalSwap",
  },
  openGraph: {
    title: "TropicalSwap - A next evolution DeFi exchange on Mantle (MNT)",
    description:
      "Earn $PAPPLE through yield farming, then stake it in Tropical Pools to earn more tokens! Initial Farm Offerings, NFTs, and more, on the freshest and most exotic AMM DEX on Mantle.",
    images: [{ url: "/favicon.ico" }],
  },
};
