import { useContext, useCallback, useMemo, useEffect } from "react";
import Cookie from "js-cookie";
import { ThemeContext as StyledThemeContext } from "styled-components";
import { useTheme as useNextTheme } from "next-themes";

/* export const COOKIE_THEME_KEY = "theme";
export const THEME_DOMAIN = ".tropicalswap.exchange";

const useTheme = () => {
  const { resolvedTheme, setTheme } = useNextTheme();
  const theme = useContext(StyledThemeContext);

  const handleSwitchTheme = useCallback(
    (themeValue: "light" | "dark") => {
      try {
        setTheme(themeValue);
        Cookie.set(COOKIE_THEME_KEY, themeValue, { domain: THEME_DOMAIN });
      } catch (err) {
        // ignore set cookie error for perp theme
      }
    },
    [setTheme]
  );

  return useMemo(
    () => ({
      isDark: resolvedTheme === "dark",
      theme,
      setTheme: handleSwitchTheme,
    }),
    [theme, resolvedTheme, handleSwitchTheme]
  );
};

export default useTheme; */

export const COOKIE_THEME_KEY = "theme";
export const THEME_DOMAIN = ".tropicalswap.exchange";

const useTheme = () => {
  const { setTheme } = useNextTheme();
  const theme = useContext(StyledThemeContext);

  const handleSwitchTheme = useCallback(
    (themeValue: "dark" | "light") => { // Force le thème à être toujours sombre
      try {
        setTheme(themeValue);
        Cookie.set(COOKIE_THEME_KEY, themeValue, { domain: THEME_DOMAIN });
      } catch (err) {
        // ignore set cookie error for perp theme
      }
    },
    [setTheme]
  );

  // Définir le thème comme sombre lors de l'initialisation
  useEffect(() => {
    handleSwitchTheme("dark");
  }, [handleSwitchTheme]);

  return useMemo(
    () => ({
      isDark: true, // Force isDark à être toujours vrai
      theme,
      setTheme: handleSwitchTheme,
    }),
    [theme, handleSwitchTheme]
  );
};

export default useTheme;
