import { ChainId } from "@pancakeswap/sdk";
import memoize from "lodash/memoize";
import invert from "lodash/invert";

export const CHAIN_QUERY_NAME = {
  [ChainId.ETHEREUM]: "eth",
  [ChainId.GOERLI]: "goerli",
  [ChainId.BSC]: "bsc",
  [ChainId.BSC_TESTNET]: "bscTestnet",
  [ChainId.MANTLE_TESTNET]: "mntTestnet",
  [ChainId.MANTLE]: "mnt",
  [ChainId.FIVEIRETESTNET]: "5ireTestnet",
  [ChainId.FIVEIRE]: "5ire",
} satisfies Record<ChainId, string>;

export const CHAIN_QUERY_FULL_NAME = {
  [ChainId.ETHEREUM]: "Ethereum",
  [ChainId.GOERLI]: "Goerli",
  [ChainId.BSC]: "Binance Smart Chain",
  [ChainId.BSC_TESTNET]: "Binance Smart Chain Testnet",
  [ChainId.MANTLE_TESTNET]: "Mantle Testnet",
  [ChainId.MANTLE]: "Mantle",
  [ChainId.FIVEIRETESTNET]: "5ire Testnet",
  [ChainId.FIVEIRE]: "5ire",
} satisfies Record<ChainId, string>;

const CHAIN_QUERY_NAME_TO_ID = invert(CHAIN_QUERY_NAME);

export const getChainId = memoize((chainName: string) => {
  if (!chainName) return undefined;
  return CHAIN_QUERY_NAME_TO_ID[chainName]
    ? +CHAIN_QUERY_NAME_TO_ID[chainName]
    : undefined;
});

export const getChainName = memoize((chainId: number) => {
  return CHAIN_QUERY_FULL_NAME[chainId] || "";
});
