import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    // <Svg viewBox="0 0 24 24" {...props}>
    //   <path d="M12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM13 17H11V15H13V17Z" />
    // </Svg>
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clip-path="url(#clip0_4089_67744)">
        <path
          d="M12.5 13L6.5625 8.54688C6.46603 8.47359 6.38768 8.37912 6.33349 8.27075C6.27931 8.16239 6.25075 8.04303 6.25 7.92188V4.40625C6.25 4.19905 6.33231 4.00034 6.47882 3.85382C6.62534 3.70731 6.82405 3.625 7.03125 3.625H17.9688C18.176 3.625 18.3747 3.70731 18.5212 3.85382C18.6677 4.00034 18.75 4.19905 18.75 4.40625V7.88281C18.7493 8.00396 18.7207 8.12333 18.6665 8.23169C18.6123 8.34005 18.534 8.43452 18.4375 8.50781L12.5 13Z"
          stroke="#400C8B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12.5 13L6.5625 17.4531C6.46603 17.5264 6.38768 17.6209 6.33349 17.7292C6.27931 17.8376 6.25075 17.957 6.25 18.0781V21.5938C6.25 21.801 6.33231 21.9997 6.47882 22.1462C6.62534 22.2927 6.82405 22.375 7.03125 22.375H17.9688C18.176 22.375 18.3747 22.2927 18.5212 22.1462C18.6677 21.9997 18.75 21.801 18.75 21.5938V18.1172C18.7493 17.996 18.7207 17.8767 18.6665 17.7683C18.6123 17.6599 18.534 17.5655 18.4375 17.4922L12.5 13Z"
          stroke="#400C8B"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4089_67744">
          <rect width="25" height="25" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Icon;