import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import { USDC, USDT, WMNT_MNT, WETH_MNT, METH_MNT, PAPPLE_MNT, SEEDS } from '@pancakeswap/tokens'

export const mantleTokens = {
  papple: PAPPLE_MNT,
  mnt: WMNT_MNT,
  usdt: USDT[ChainId.MANTLE],
  usdc: USDC[ChainId.MANTLE],
  weth: WETH_MNT,
  meth: METH_MNT,
  seeds: SEEDS,
}
