import { ChainId } from "@pancakeswap/sdk";

export const SUPPORT_ONLY_BSC = [ChainId.BSC];

export const SUPPORT_ONLY_MANTLE = [ChainId.MANTLE];

export const SUPPORT_FARMS = [
  // ChainId.BSC,
  // ChainId.BSC_TESTNET,
  // ChainId.ETHEREUM,
  // ChainId.GOERLI,
  ChainId.MANTLE_TESTNET,
  ChainId.MANTLE,
];

export const SUPPORT_ZAP = [
  ChainId.BSC,
  ChainId.BSC_TESTNET,
  ChainId.MANTLE_TESTNET,
  ChainId.MANTLE,
  ChainId.FIVEIRETESTNET,
  ChainId.FIVEIRE,
];
